import React from 'react'
import styled from 'styled-components'
import { List, ListItem, Checkbox, FormGroup, FormControlLabel, Box } from '@mui/material'

interface SidebarProps {
  filters: { [key: string]: boolean }
  onFilterChange: (filterName: string, checked: boolean) => void
}

const ContainerBox = styled(Box)({
  border: '1px solid black',
  maxWidth: '200px',
  position: 'relative',
  margin: '56px 32px 0'
})

const TitleBox = styled(Box)({
  background: 'white',
  position: 'absolute',
  top: '-10px',
  left: '10px',
  padding: '0 5px',
  fontWeight: 'bold',
  height: 'auto'
})

const ContainerComponent = ({ title, children }: { title: string; children: React.ReactElement }) => (
  <ContainerBox>
    <TitleBox>{title}</TitleBox>
    <>{children}</>
  </ContainerBox>
)

const Filters = (props: { filters: any; onFilterChange: any }) => {
  const { filters, onFilterChange } = props
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(event.target.name, event.target.checked)
  }
  return (
    <List component="nav">
      {Object.keys(filters).map(filterName => (
        <ListItem sx={{ py: 0 }} key={filterName}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={filters[filterName]} onChange={handleCheckboxChange} name={filterName} />}
              label={filterName}
            />
          </FormGroup>
        </ListItem>
      ))}
    </List>
  )
}

// const Sources = (props: { sources: any; onSourcesChange: any }) => {
//   const { sources, onSourcesChange } = props
//   const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     onSourcesChange(event.target.name, event.target.checked)
//   }
//   return (
//     <List component="nav">
//       {Object.keys(sources).map(filterName => (
//         <ListItem sx={{ py: 0 }} key={filterName}>
//           <FormGroup>
//             <FormControlLabel
//               control={<Checkbox checked={sources[filterName]} onChange={handleCheckboxChange} name={filterName} />}
//               label={filterName}
//             />
//           </FormGroup>
//         </ListItem>
//       ))}
//     </List>
//   )
// }

export const Sidebar: React.FC<SidebarProps> = props => {
  const { filters, onFilterChange } = props
  return (
    <>
      <ContainerComponent title="Filter by">
        <Filters filters={filters} onFilterChange={onFilterChange} />
      </ContainerComponent>
    </>
  )
}
