import React from 'react'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableContainer as UnstyledTableContainer, TableHead, TableRow, Paper } from '@mui/material'

interface GPU {
  id: string
  name: string
  price: number
  manufacturer: string
  memorySize: string
  coreClock: string
}

interface DataTableProps {
  gpus: GPU[]
}

const TableContainer = styled(UnstyledTableContainer)`
  max-width: 100%;
`

const TableCellHeader = styled(TableCell)`
  font-weight: 700 !important;
`

export const DataTable: React.FC<DataTableProps> = ({ gpus }) => {
  return (
    <TableContainer>
      <Paper>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellHeader>Name</TableCellHeader>
              <TableCellHeader align="right">Price ($)</TableCellHeader>
              <TableCellHeader align="right">Manufacturer</TableCellHeader>
              <TableCellHeader align="right">Memory Size</TableCellHeader>
              <TableCellHeader align="right">Core Clock</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {gpus.map(gpu => (
              <TableRow key={gpu.id}>
                <TableCell component="th" scope="row">
                  {gpu.name}
                </TableCell>
                <TableCell align="right">{gpu.price}</TableCell>
                <TableCell align="right">{gpu.manufacturer}</TableCell>
                <TableCell align="right">{gpu.memorySize}</TableCell>
                <TableCell align="right">{gpu.coreClock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  )
}
