import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #f3f3f3;
  border-top: 1px solid #d1d1d1;
  font-size: 12px;
  color: #333;
`

export const Footer: React.FC = () => {
  const currentDate = new Date().toLocaleString('en-US', { timeZone: 'UTC' })
  return (
    <FooterContainer>
      <p>Last updated at {currentDate}.</p>
      <p>
        As an Amazon Associate I earn from qualifying purchases. Product prices and availability are accurate as of the date/time indicated
        and are subject to change. Any price and availability information displayed at the time of purchase will apply to the purchase of
        the product. CERTAIN CONTENT THAT APPEARS ON THIS SITE COMES FROM AMAZON SERVICES LLC. THIS CONTENT IS PROVIDED 'AS IS' AND IS
        SUBJECT TO CHANGE OR REMOVAL AT ANY TIME.
      </p>
    </FooterContainer>
  )
}
