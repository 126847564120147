import { GPU, FilterState } from './interfaces'

export const filterState: FilterState = {
  NVIDIA: false,
  AMD: false,
  ASUS: false,
  MSI: false,
  EVGA: false,
  Gigabyte: false,
  ZOTAC: false,
  PNY: false,
  Sapphire: false,
  PowerColor: false,
  '4GB': false,
  '6GB': false,
  '8GB': false,
  '10GB': false,
  '12GB': false,
  '16GB': false,
  '24GB': false
}

export const gpuData: GPU[] = [
  {
    id: 'gpu1',
    name: 'NVIDIA RTX 3080',
    price: 699.99,
    manufacturer: 'NVIDIA',
    memorySize: '10GB',
    coreClock: '1.44GHz'
  },
  {
    id: 'gpu2',
    name: 'AMD Radeon RX 6900 XT',
    price: 799.99,
    manufacturer: 'AMD',
    memorySize: '16GB',
    coreClock: '1.82GHz'
  },
  {
    id: 'gpu3',
    name: 'ASUS GeForce RTX 3070',
    price: 599.99,
    manufacturer: 'ASUS',
    memorySize: '8GB',
    coreClock: '1.73GHz'
  },
  {
    id: 'gpu4',
    name: 'MSI GeForce GTX 1660 Ti',
    price: 299.99,
    manufacturer: 'MSI',
    memorySize: '6GB',
    coreClock: '1.5GHz'
  },
  {
    id: 'gpu5',
    name: 'EVGA GeForce RTX 3060',
    price: 399.99,
    manufacturer: 'EVGA',
    memorySize: '12GB',
    coreClock: '1.32GHz'
  },
  {
    id: 'gpu6',
    name: 'Gigabyte Radeon RX 6700 XT',
    price: 549.99,
    manufacturer: 'Gigabyte',
    memorySize: '12GB',
    coreClock: '1.7GHz'
  },
  {
    id: 'gpu7',
    name: 'ZOTAC Gaming GeForce GTX 1650',
    price: 199.99,
    manufacturer: 'ZOTAC',
    memorySize: '4GB',
    coreClock: '1.49GHz'
  },
  {
    id: 'gpu8',
    name: 'PNY GeForce RTX 3090',
    price: 1499.99,
    manufacturer: 'PNY',
    memorySize: '24GB',
    coreClock: '1.4GHz'
  },
  {
    id: 'gpu9',
    name: 'Sapphire Radeon RX 6800',
    price: 649.99,
    manufacturer: 'Sapphire',
    memorySize: '16GB',
    coreClock: '1.75GHz'
  },
  {
    id: 'gpu10',
    name: 'PowerColor Radeon RX 6600 XT',
    price: 329.99,
    manufacturer: 'PowerColor',
    memorySize: '8GB',
    coreClock: '1.85GHz'
  }
]

export const amazonSources = [
  { label: 'amazon.com', url: 'https://www.amazon.com' },
  { label: 'amazon.co.uk', url: 'https://www.amazon.co.uk' },
  { label: 'amazon.de', url: 'https://www.amazon.de' },
  { label: 'amazon.es', url: 'https://www.amazon.es' },
  { label: 'amazon.fr', url: 'https://www.amazon.fr' },
  { label: 'amazon.ca', url: 'https://www.amazon.ca' },
  { label: 'amazon.com.au', url: 'https://www.amazon.com.au' },
  { label: 'amazon.in', url: 'https://www.amazon.in' },
  { label: 'amazon.se', url: 'https://www.amazon.se' }
]

// export const gpuData: GPU[] = [
//   {
//     id: 'gpu2',
//     name: 'AMD Radeon RX 6900 XT',
//     price: 999.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6900 XT',
//     coreClockSpeed: '2.25GHz',
//     memorySize: '16GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '16Gbps',
//     memoryBandwidth: '512 GB/s',
//     cudaCoresOrStreamProcessors: 5120,
//     tdp: '300W',
//     rayTracingSupport: true,
//     busInterface: 'PCIe 4.0 x16',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x3'],
//     formFactor: '2.5 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 5120,
//     rops: 128,
//     tmus: 320
//   },
//   {
//     id: 'gpu3',
//     name: 'NVIDIA RTX 3070',
//     price: 499.99,
//     manufacturer: 'NVIDIA',
//     model: 'RTX 3070',
//     coreClockSpeed: '1.50GHz',
//     memorySize: '8GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '14Gbps',
//     memoryBandwidth: '448 GB/s',
//     cudaCoresOrStreamProcessors: 5888,
//     tdp: '220W',
//     rayTracingSupport: true,
//     busInterface: 'PCIe 4.0 x16',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4a x3'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 5888,
//     rops: 96,
//     tmus: 184
//   },
//   {
//     id: 'gpu4',
//     name: 'AMD Radeon RX 6800 XT',
//     price: 799.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6800 XT',
//     coreClockSpeed: '2.25GHz',
//     memorySize: '16GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '16Gbps',
//     memoryBandwidth: '512 GB/s',
//     cudaCoresOrStreamProcessors: 4608,
//     tdp: '300W',
//     rayTracingSupport: true,
//     busInterface: 'PCIe 4.0 x16',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x3'],
//     formFactor: '2.5 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 4608,
//     rops: 128,
//     tmus: 288
//   },
//   {
//     id: 'gpu5',
//     name: 'NVIDIA GTX 1660 Super',
//     price: 249.99,
//     manufacturer: 'NVIDIA',
//     model: 'GTX 1660 Super',
//     coreClockSpeed: '1.53GHz',
//     memorySize: '6GB',
//     memoryType: 'GDDR5',
//     memorySpeed: '14Gbps',
//     memoryBandwidth: '336 GB/s',
//     cudaCoresOrStreamProcessors: 1408,
//     tdp: '125W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 3.0 x16',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x3'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 1408,
//     rops: 48,
//     tmus: 88
//   },
//   {
//     id: 'gpu6',
//     name: 'AMD Radeon RX 6700 XT',
//     price: 479.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6700 XT',
//     coreClockSpeed: '2.30GHz',
//     memorySize: '12GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '16Gbps',
//     memoryBandwidth: '384 GB/s',
//     cudaCoresOrStreamProcessors: 2560,
//     tdp: '230W',
//     rayTracingSupport: true,
//     busInterface: 'PCIe 4.0 x16',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x3'],
//     formFactor: '2.5 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 2560,
//     rops: 64,
//     tmus: 160
//   },
//   {
//     id: 'gpu7',
//     name: 'NVIDIA GTX 1650 Super',
//     price: 169.99,
//     manufacturer: 'NVIDIA',
//     model: 'GTX 1650 Super',
//     coreClockSpeed: '1.53GHz',
//     memorySize: '4GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '12Gbps',
//     memoryBandwidth: '192 GB/s',
//     cudaCoresOrStreamProcessors: 1280,
//     tdp: '100W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 3.0 x16',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x1', 'DVI-D'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 1280,
//     rops: 32,
//     tmus: 80
//   },
//   {
//     id: 'gpu8',
//     name: 'AMD Radeon RX 6600 XT',
//     price: 299.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6600 XT',
//     coreClockSpeed: '2.59GHz',
//     memorySize: '8GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '15Gbps',
//     memoryBandwidth: '240 GB/s',
//     cudaCoresOrStreamProcessors: 2048,
//     tdp: '160W',
//     rayTracingSupport: true,
//     busInterface: 'PCIe 4.0 x16',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x3'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 2048,
//     rops: 32,
//     tmus: 128
//   },
//   {
//     id: 'gpu9',
//     name: 'NVIDIA GTX 1660 Ti',
//     price: 279.99,
//     manufacturer: 'NVIDIA',
//     model: 'GTX 1660 Ti',
//     coreClockSpeed: '1.50GHz',
//     memorySize: '6GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '12Gbps',
//     memoryBandwidth: '288 GB/s',
//     cudaCoresOrStreamProcessors: 1536,
//     tdp: '120W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 3.0 x16',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x1', 'DVI-D'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 1536,
//     rops: 48,
//     tmus: 96
//   },
//   {
//     id: 'gpu10',
//     name: 'AMD Radeon RX 6500 XT',
//     price: 199.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6500 XT',
//     coreClockSpeed: '2.00GHz',
//     memorySize: '4GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '12Gbps',
//     memoryBandwidth: '192 GB/s',
//     cudaCoresOrStreamProcessors: 1024,
//     tdp: '85W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 4.0 x8',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x2'],
//     formFactor: '1.5 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 1024,
//     rops: 16,
//     tmus: 64
//   },
//   {
//     id: 'gpu11',
//     name: 'NVIDIA GTX 1650',
//     price: 149.99,
//     manufacturer: 'NVIDIA',
//     model: 'GTX 1650',
//     coreClockSpeed: '1.48GHz',
//     memorySize: '4GB',
//     memoryType: 'GDDR5',
//     memorySpeed: '8Gbps',
//     memoryBandwidth: '128 GB/s',
//     cudaCoresOrStreamProcessors: 896,
//     tdp: '75W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 3.0 x16',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x1', 'DVI-D'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 896,
//     rops: 24,
//     tmus: 56
//   },
//   {
//     id: 'gpu12',
//     name: 'AMD Radeon RX 6400',
//     price: 99.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 6400',
//     coreClockSpeed: '1.80GHz',
//     memorySize: '2GB',
//     memoryType: 'GDDR5',
//     memorySpeed: '8Gbps',
//     memoryBandwidth: '64 GB/s',
//     cudaCoresOrStreamProcessors: 384,
//     tdp: '45W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 4.0 x4',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x1', 'DVI-D'],
//     formFactor: '1.5 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 384,
//     rops: 8,
//     tmus: 32
//   },
//   {
//     id: 'gpu13',
//     name: 'NVIDIA GT 1030',
//     price: 79.99,
//     manufacturer: 'NVIDIA',
//     model: 'GT 1030',
//     coreClockSpeed: '1.23GHz',
//     memorySize: '2GB',
//     memoryType: 'GDDR5',
//     memorySpeed: '6Gbps',
//     memoryBandwidth: '48 GB/s',
//     cudaCoresOrStreamProcessors: 384,
//     tdp: '30W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 3.0 x4',
//     ports: ['HDMI 2.0b', 'DisplayPort 1.4 x1'],
//     formFactor: '1.5 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 384,
//     rops: 16,
//     tmus: 24
//   },
//   {
//     id: 'gpu14',
//     name: 'AMD Radeon RX 5500 XT',
//     price: 179.99,
//     manufacturer: 'AMD',
//     model: 'Radeon RX 5500 XT',
//     coreClockSpeed: '1.71GHz',
//     memorySize: '4GB',
//     memoryType: 'GDDR6',
//     memorySpeed: '14Gbps',
//     memoryBandwidth: '224 GB/s',
//     cudaCoresOrStreamProcessors: 1408,
//     tdp: '130W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 4.0 x8',
//     ports: ['HDMI 2.1', 'DisplayPort 1.4 x2'],
//     formFactor: '2 slot',
//     directXVersion: 'DirectX 12 Ultimate',
//     openGLVersion: 'OpenGL 4.6',
//     shadingUnits: 1408,
//     rops: 32,
//     tmus: 88
//   },
//   {
//     id: 'gpu15',
//     name: 'NVIDIA GT 710',
//     price: 49.99,
//     manufacturer: 'NVIDIA',
//     model: 'GT 710',
//     coreClockSpeed: '0.95GHz',
//     memorySize: '2GB',
//     memoryType: 'DDR3',
//     memorySpeed: '1.8Gbps',
//     memoryBandwidth: '14.4 GB/s',
//     cudaCoresOrStreamProcessors: 192,
//     tdp: '25W',
//     rayTracingSupport: false,
//     busInterface: 'PCIe 2.0 x8',
//     ports: ['HDMI 1.4 x1', 'VGA x1'],
//     formFactor: '1 slot',
//     directXVersion: 'DirectX 12',
//     openGLVersion: 'OpenGL 4.5',
//     shadingUnits: 192,
//     rops: 8,
//     tmus: 16
//   }
// ]
