import React, { useState } from 'react'
import styled from 'styled-components'
import { getCLS, getFID, getLCP } from 'web-vitals'
import { Helmet } from 'react-helmet'
// import { useQuery } from '@tanstack/react-query'

import { Sidebar } from './components/Sidebar'
import { DataTable } from './components/DataTable'
import { Footer } from './components/Footer'

import { gpuData, filterState } from './mock-data'
import { GPU, FilterState } from './interfaces'

getCLS(console.log)
getFID(console.log)
getLCP(console.log)

const App: React.FC = () => {
  const [gpus, setGpus] = useState<GPU[]>(gpuData)
  // const { data, isLoading, error } = useQuery(['gpus'], fetchGPUs)

  // async function fetchGPUs() {
  //   const response = await apiClient.get('/gpus')
  //   return 'response.data'
  // }

  const [filters, setFilters] = useState<FilterState>(filterState)

  const AppContainer = styled.div`
    display: flex;
  `

  const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  `

  React.useEffect(() => {
    // Check if any manufacturer or memory size filter is active
    const isManufacturerFilterActive = Object.keys(filters).some(key => filters[key] && gpuData.some(gpu => gpu.manufacturer === key))
    const isMemorySizeFilterActive = Object.keys(filters).some(key => filters[key] && gpuData.some(gpu => gpu.memorySize === key))

    const filteredGpus = gpuData.filter(gpu => {
      // Check for manufacturer and memory size filter matches
      const matchesManufacturer = isManufacturerFilterActive ? filters[gpu.manufacturer] : true
      const matchesMemorySize = isMemorySizeFilterActive ? filters[gpu.memorySize] : true

      return matchesManufacturer && matchesMemorySize
    })

    setGpus(filteredGpus)
  }, [filters])

  const handleFilterChange = (filterName: string, checked: boolean) => {
    setFilters(prev => ({ ...prev, [filterName]: checked }))
    console.log('filters', filters)
  }

  return (
    <AppContainer>
      <Helmet>
        <meta property="og:title" content="GPU Price Check" />
        <meta property="og:description" content="Find the best prices for graphics cards" />
        <meta property="og:image" content="http://example.com/gpu-image.jpg" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Store",
              "name": "GPU Price Check",
              "description": "Find the best prices for graphics cards",
              "url": "http://www.gpupricecheck.com"
            }
          `}
        </script>
        <title>GPU Price Check</title>
        <meta name="description" content="Find the best prices for graphics cards." />
      </Helmet>
      <Sidebar filters={filters} onFilterChange={handleFilterChange} />
      <MainContent>
        <DataTable gpus={gpus} />
        <Footer />
      </MainContent>
    </AppContainer>
  )
}

export default App
